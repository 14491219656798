export default {
    menu: {
        root: {
            "class": 'py-1 bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border border-gray-300 dark:border-blue-900/40 rounded-md w-48'
        },
        menu: {
            "class": ['m-0 p-0 list-none', 'outline-none']
        },
        content: function content(_ref98) {
            var context = _ref98.context;
            return {
                "class": ['text-gray-700 dark:text-white/80 transition-shadow duration-200 rounded-none', 'hover:text-gray-700 dark:hover:text-white/80 hover:bg-gray-200 dark:hover:bg-gray-800/80',
                    // Hover
                    {
                        'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90': context.focused
                    }]
            };
        },
        action: {
            "class": ['text-gray-700 dark:text-white/80 py-3 px-5 select-none', 'cursor-pointer flex items-center no-underline overflow-hidden relative']
        },
        icon: {
            "class": 'text-gray-600 dark:text-white/70 mr-2'
        },
        submenuheader: {
            "class": ['m-0 p-3 text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 font-bold rounded-tl-none rounded-tr-none']
        },

    },
    button: {
        root: (options) =>
            ({
                class: [
                    'items-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom', 'transition duration-200 ease-in-out', 'focus:outline-none focus:outline-offset-0', {
                        'text-white dark:text-gray-900 bg-blue-500 dark:bg-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-600 dark:hover:bg-blue-500 hover:border-blue-600 dark:hover:border-blue-500 focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]': !options.props.link && options.props.severity === null && !options.props.text && !options.props.outlined && !options.props.plain,
                        'text-blue-600 bg-transparent border-transparent focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]': options.props.link
                    }, {
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(176,185,198,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(203,213,225,0.7),0_1px_2px_0_rgba(0,0,0,0)]': options.props.severity === 'secondary',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(136,234,172,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(134,239,172,0.7),0_1px_2px_0_rgba(0,0,0,0)]': options.props.severity === 'success',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]': options.props.severity === 'info',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(250,207,133,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,211,77,0.7),0_1px_2px_0_rgba(0,0,0,0)]': options.props.severity === 'warning',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(212,170,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(216,180,254,0.7),0_1px_2px_0_rgba(0,0,0,0)]': options.props.severity === 'help',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(247,162,162,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,165,165,0.7),0_1px_2px_0_rgba(0,0,0,0)]': options.props.severity === 'danger'
                    }, {
                        'text-white dark:text-gray-900 bg-gray-500 dark:bg-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-600 dark:hover:bg-gray-500 hover:border-gray-600 dark:hover:border-gray-500': options.props.severity === 'secondary' && !options.props.text && !options.props.outlined && !options.props.plain,
                        'text-white dark:text-gray-900 bg-green-500 dark:bg-green-400 border border-green-500 dark:border-green-400 hover:bg-green-600 dark:hover:bg-green-500 hover:border-green-600 dark:hover:border-green-500': options.props.severity === 'success' && !options.props.text && !options.props.outlined && !options.props.plain,
                        'text-white dark:text-gray-900 dark:bg-blue-400 bg-blue-500 dark:bg-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-600 hover:border-blue-600 dark:hover:bg-blue-500 dark:hover:border-blue-500': options.props.severity === 'info' && !options.props.text && !options.props.outlined && !options.props.plain,
                        'text-white dark:text-gray-900 bg-orange-500 dark:bg-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-600 dark:hover:bg-orange-500 hover:border-orange-600 dark:hover:border-orange-500': options.props.severity === 'warning' && !options.props.text && !options.props.outlined && !options.props.plain,
                        'text-white dark:text-gray-900 bg-purple-500 dark:bg-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-600 dark:hover:bg-purple-500 hover:border-purple-600 dark:hover:border-purple-500': options.props.severity === 'help' && !options.props.text && !options.props.outlined && !options.props.plain,
                        'text-white dark:text-gray-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 hover:bg-red-600 dark:hover:bg-red-500 hover:border-red-600 dark:hover:border-red-500': options.props.severity === 'danger' && !options.props.text && !options.props.outlined && !options.props.plain
                    }, {
                        'shadow-lg': options.props.raised
                    }, {
                        'rounded-md': !options.props.rounded,
                        'rounded-full': options.props.rounded
                    }, {
                        'bg-transparent border-transparent': options.props.text && !options.props.plain,
                        'text-blue-500 dark:text-blue-400 hover:bg-blue-300/20': options.props.text && (options.props.severity === null || options.props.severity === 'info') && !options.props.plain,
                        'text-gray-500 dark:text-gray-400 hover:bg-gray-300/20': options.props.text && options.props.severity === 'secondary' && !options.props.plain,
                        'text-green-500 dark:text-green-400 hover:bg-green-300/20': options.props.text && options.props.severity === 'success' && !options.props.plain,
                        'text-orange-500 dark:text-orange-400 hover:bg-orange-300/20': options.props.text && options.props.severity === 'warning' && !options.props.plain,
                        'text-purple-500 dark:text-purple-400 hover:bg-purple-300/20': options.props.text && options.props.severity === 'help' && !options.props.plain,
                        'text-red-500 dark:text-red-400 hover:bg-red-300/20': options.props.text && options.props.severity === 'danger' && !options.props.plain
                    }, {
                        'shadow-lg': options.props.raised && options.props.text
                    }, {
                        'text-gray-500 hover:bg-gray-300/20': options.props.plain && options.props.text,
                        'text-gray-500 border border-gray-500 hover:bg-gray-300/20': options.props.plain && options.props.outlined,
                        'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600': options.props.plain && !options.props.outlined && !options.props.text
                    }, {
                        'bg-transparent border': options.props.outlined && !options.props.plain,
                        'text-blue-500 dark:text-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-300/20': options.props.outlined && (options.props.severity === null || options.props.severity === 'info') && !options.props.plain,
                        'text-gray-500 dark:text-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-300/20': options.props.outlined && options.props.severity === 'secondary' && !options.props.plain,
                        'text-green-500 dark:text-green-400 border border-green-500 dark:border-green-400 hover:bg-green-300/20': options.props.outlined && options.props.severity === 'success' && !options.props.plain,
                        'text-orange-500 dark:text-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-300/20': options.props.outlined && options.props.severity === 'warning' && !options.props.plain,
                        'text-purple-500 dark:text-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-300/20': options.props.outlined && options.props.severity === 'help' && !options.props.plain,
                        'text-red-500 dark:text-red-400 border border-red-500 dark:border-red-400 hover:bg-red-300/20': options.props.outlined && options.props.severity === 'danger' && !options.props.plain
                    }, {
                        'px-4 py-3 text-base': options.props.size === null,
                        'text-xs py-2 px-3': options.props.size === 'small',
                        'text-xl py-3 px-4': options.props.size === 'large'
                    }, {
                        'flex-column': options.props.iconPos == 'top' || options.props.iconPos == 'bottom'
                    }, {
                        'opacity-60 pointer-events-none cursor-default': options.context.disabled
                    }]
            })
        }

}
